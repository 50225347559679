
import Vue from 'vue'
import { emailAllowedSymbolsRegExp, passwordAllowedSymbolsRegExp } from '@/constants/baseConstatnts'

export default Vue.extend({
  name: 'loginKYC',
  data: () => ({
    form: {
      valid: false,
      fields: {
        email: {
          value: '',
          rules: [
            (v: string) => Boolean(v.length) || 'login.errors.loginRequired',
            (v: string) => emailAllowedSymbolsRegExp.test(v.trim()) || 'login.errors.emailIncorrect'
          ],
          isAutofilled: false,
          pattern: '[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'
        },
        password: {
          value: '',
          type: 'password',
          rules: [
            (v: string) => Boolean(v.length) || 'login.errors.passwordRequired'
          ],
          isAutofilled: false,
          pattern: passwordAllowedSymbolsRegExp.source
        }
      },
      successLogin: false,
      successTimer: 0,
      responseErrors: [],
      isLoading: false
    }
  }),
  methods: {
    async login () {
      this.form.isLoading = true
      const requestData = {
        email: this.form.fields.email.value,
        password: this.form.fields.password.value
      }
      const response = await this.$services.authorization.signin.doSignInKYC(requestData)
      if (response.success) {
        if (response.status === 200) {
          this.$router.push({ name: 'whitelistIDUserAccount' })
        } else if (response.status === 202) {
          const method = Object.keys(response.data.confirmations)[0]
          this.$router.push({ name: 'SignInSecondFactorKYC', params: { method }, query: { an3K8QkkJb3Mzz: response.data.session_id } })
        } else if (response.status === 210) {
          const vm = this as any
          this.$store.dispatch('popupModule/openPopup', {
            type: 'confirmPopup',
            letDefaultClose: false,
            title: 'Не удалось перенести КУС',
            content: `На указанном аккаунте ${this.form.fields.email.value} отсутствует завершённая верификация KYC.<br> В связи с этим необходимо пройти процедуру проверки KYC.` +
              '<br> Вы можете указать другой аккаунт, на котором пройдена верификация KYC.',
            actions: [
              {
                title: 'К верификации',
                isAccent: true,
                callback () {
                  vm.$store.commit('popupModule/setClosed')
                  vm.$router.replace({ name: 'whitelistIDUserAccount' })
                }
              },
              {
                title: 'Другой аккаунт',
                isAccent: false,
                callback () {
                  vm.$store.commit('popupModule/setClosed')
                }
              }
            ]
          })
        } else {
          this.$store.dispatch('alertsBus/openAlert', {
            title: 'Ошибка',
            type: 'error',
            autoDelete: true,
            content: 'Не удалось перенести аккаунт'
          })
        }
      } else {
        this.form.responseErrors = []
        for (const [key, value] of Object.entries(response.data) as any) {
          for (const err of value) {
            this.form.responseErrors.push(err)
          }
        }
      }

      this.form.isLoading = false
    },
    handleAutofill (scope: 'email' | 'password', e: any): void {
      const autofillAnims = ['autofillDark', 'autofillLight']
      const isAutofilled = autofillAnims.includes(e.animationName)

      if (isAutofilled) {
        this.form.fields[scope].isAutofilled = true
      }
    },
    handleChange (scope: 'email' | 'password', v: string): void {
      this.$refs.form.validate()
      this.form.fields[scope].value = v
      this.form.fields[scope].isAutofilled = false
    },
    changePasswordInputType (): void {
      this.form.fields.password.type = this.form.fields.password.type === 'password' ? 'text' : 'password'
    }
  }
})
